import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import './scss/style.scss';

// window.server = "http://localhost/"
window.server = "https://kantahsalatiga.com/"
window.server1 = "http://localhost:3000/#/"
window.server2 = "https://ppnpn.kantahsalatiga.com/"

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'));
const LoginAdmin = React.lazy(() => import('./views/admin/Admin'));

const PrintCuti = React.lazy(() => import('./views/cuti/printcuti/Print'));
const PrintPrestasiKerja = React.lazy(() => import('./views/skp/nilaiprestasikerja/Print'));
const PrintBuatSkp = React.lazy(() => import('./views/skp/buatskp/Print'));
const PrintSkpTahunan = React.lazy(() => import('./views/skp/nilaiskp/Print'));
const PrintNilaiPerilaku = React.lazy(() => import('./views/skp/nilaiperilaku/PrintNilaiPerilaku'));
const Register = React.lazy(() => import('./views/pages/register/Register'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));
const Logout = React.lazy(() => import('./views/pages/logout/Logout'));

class App extends Component {

  render() {
    return (
      <HashRouter>
          <React.Suspense fallback={loading}>
            <Switch>
              <Route exact path="/login" name="Login Page" render={props => <Login {...props}/>} />
              <Route exact path="/admin" name="Login Admin" render={props => <LoginAdmin {...props}/>} />
              <Route exact path="/logout" name="Logout Page" render={props => <Logout {...props}/>} />
              <Route exact path="/printcuti" name="Print Cuti" render={props => <PrintCuti {...props}/>} />
              <Route exact path="/printprestasikerja" name="Print Prestasi Kerja" render={props => <PrintPrestasiKerja {...props}/>} />
              <Route exact path="/printbuatskp" name="Print Buat SKP" render={props => <PrintBuatSkp {...props}/>} />
              <Route exact path="/printskptahunan" name="Print SKP Tahunan" render={props => <PrintSkpTahunan {...props}/>} />
              <Route exact path="/printnilaiperilaku" name="Print Nilai Perilaku" render={props => <PrintNilaiPerilaku {...props}/>} />
              <Route exact path="/register" name="Register Page" render={props => <Register {...props}/>} />
              <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>} />
              <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>} />
              <Route path="/" name="Home" render={props => <TheLayout {...props}/>} />
            </Switch>
          </React.Suspense>
      </HashRouter>
    );
  }
}

export default App;
